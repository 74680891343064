import { faFile, faGlobe, faHandPeace } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Slider from "react-slick"
// import "../utils/global.scss"
import CloudImg from "../../content/assets/illustrations/mobile/cloud.svg"
import ChargeImg from "../../content/assets/illustrations/mobile/destination.svg"
import MobileImg from "../../content/assets/illustrations/mobile/mapps.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const Index = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
  }
  return (
    <Layout title={siteTitle} currentLink="/">
      <SEO
        title="Trunkroad - AI driven B2B Commerce platform"
        description="Trunkroad transforms B2B companies with Digital Commerce and
        Intelligence"
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />
      <div className="banner banner-bg main-hero">
        <Img fluid={data.hero.childImageSharp.fluid} className="hero-img" />
        <div className="banner-body hero-body">
          <h1 className="caption">The digital highway for your business.</h1>

          <h4 className="caption">
            Trunkroad transforms B2B companies with Digital Commerce and
            Intelligence.
          </h4>
          <div className="caption">
            <a href="#demo" className="button large">
              Get a Demo!
            </a>
          </div>
        </div>
        <div className="hero-video">
          <video loop autoPlay="autoplay" muted id="vid">
            <source
              src="https://trunkroad-engage-files-dev.s3.ap-south-1.amazonaws.com/HeroHome.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>

      <div className="sections text-center">
        <div className="section sec1">
          <div className="img1">
            <CloudImg />
          </div>
          <div className="section-txt">
            <h3>Trunkroad Hub</h3>
            <h4>World's most intelligent B2B Commerce platform.</h4>
            <div className="sec-img">
              <CloudImg />
            </div>
            <h5 className="section-text">
              <p>The super highway for your digital transformation journey.</p>
              <p>
                Trunkroad powers your business to quickly launch AI powered
                solutions and integrations including B2B eCommerce, mobile
                ordering apps, API integrations and field sales tracking.
              </p>
            </h5>
            <Link className="button outline large" to={`/b2bcloud`}>
              Know More
            </Link>
          </div>
        </div>

        <div className="section sec2">
          <div className="section-txt">
            <h3>Mobile Store</h3>
            <h4>Mobile now. Mobile first.</h4>
            <div className="sec-img">
              <MobileImg />
            </div>

            <h5 className="section-text">
              <p>
                Boost your sales with intelligent recommendations and contextual
                promotions to your customers
              </p>
              <p>
                Power your customers with a mobile app for one-click
                (re)ordering, order and shipment tracking for quick and smooth
                B2B sales.
              </p>
              <Link className="button outline large" to={`/mobilesuite`}>
                Know More
              </Link>
            </h5>
          </div>
          <div className="img2">
            <MobileImg />
          </div>
        </div>
        <div className="section sec3">
          <div className="img3">
            <ChargeImg />
          </div>
          <div className="section-txt">
            <h3>Sales Assistant</h3>
            <h4>Super charge your sales force!</h4>
            <div className="sec-img">
              <ChargeImg />
            </div>
            <h5 className="section-text">
              <p>
                Intelligent recommendations for customer visits. AI powered
                route planning and calendar.
              </p>
              <p>
                Discover valuable insights about your market, track and verify
                team activity, and communicate effortlessly across all your
                devices
              </p>
              <Link className="button outline large" to={`/salesrep`}>
                Know More
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    hero: file(relativePath: { eq: "HeroMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hub: file(relativePath: { eq: "HeroHub2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobile: file(relativePath: { eq: "HeroMobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sales: file(relativePath: { eq: "HeroSales.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { eq: "logos/1.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo2: file(relativePath: { eq: "logos/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo3: file(relativePath: { eq: "logos/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo4: file(relativePath: { eq: "logos/4.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo5: file(relativePath: { eq: "logos/5.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo6: file(relativePath: { eq: "logos/6.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo7: file(relativePath: { eq: "logos/7.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <Index location={props.location} props data={data} {...props} />
    )}
  />
)
